import { Link } from "react-router";

function WorkshopsSection() {

    return (
        <section className="about_section">
            <div className="container">
                <h2>Workshops</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-detail">
                            <p>Kom deze zomer meedansen met TM Dance Company! Kan je niet wachten tot in september voor de proefles? De hele maand augustus organiseert TMDC zomerworkshops waar jij kan kennismaken met onze danslessen en ons super leuke docententeam!</p>
                            <p>Ben je na deze les overtuigd en wil je in september starten met deze lessenreeks? Schrijf je in binnen de 7 dagen en krijg het inschrijvingsgeld van de workshop volledig terugbetaald!</p>
                            <p>Al ingeschreven en betaald voor de cursus? Dan kan je deze workshop GRATIS meevolgen!</p>
                            <p>Let op: je moet nog wel steeds inschrijven.</p>

                            <p>Bekijk ons volledig aanbod van workshops en ons lessenrooster op www.tmdancecompany.be</p>
                            <p>Voor meer info of vragen, mail ons op info@tmdancecompany.be</p>

                            <Link to="https://www.ledenbeheer.be/public/tmdancecompany">
                                <button className="btn">Inschrijven</button>
                            </Link>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-imge-box">
                            <img src="images/zomer-workshops.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default WorkshopsSection;