

function AboutUsSection() {

    return (
        <>
            <section className="about_section">
                <div className="container">
                    <h2>Over ons</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-detail">
                                <p>Welkom bij onze dansschool, opgericht door Tinne Maes in samenwerking met Chloë Van Loock en Evy Nolmans. Wij zijn een dynamisch en gepassioneerd team dat zich toelegt op het delen van onze liefde voor dans en het inspireren van dansers van alle leeftijden en niveaus.</p>
                                <p>Tinne, onze oprichtster, begon haar danscarrière als enthousiaste danseres en groeide uit tot een ervaren wedstrijddanser. Met een schat aan kennis en ervaring op zak, is Tinne vastbesloten om haar passie voor dans te delen met anderen en hen te helpen hun volledige potentieel te bereiken.</p>
                                <p>Samen hebben we een breed scala aan danslessen en -stijlen gecreëerd om aan de unieke behoeften en interesses van onze dansers te voldoen. Van ballet en urban tot G-dans en buikdans, ons aanbod is zorgvuldig samengesteld om een ​​inclusieve en stimulerende leeromgeving te bieden waar iedereen zich welkom en gewaardeerd voelt.</p>
                                <p>Bij onze dansschool streven we ernaar om een ​​positieve en ondersteunende gemeenschap op te bouwen waarin onze dansers kunnen groeien, zowel op technisch als persoonlijk vlak. We moedigen creativiteit, zelfexpressie en vriendschap aan en zijn er trots op om te zien hoe onze dansers zich ontwikkelen en bloeien.</p>
                                <p>Laat je in onze inspirerende dansschool begeleiden op je dansreis, terwijl je nieuwe vaardigheden leert, onvergetelijke ervaringen opdoet en deel uitmaakt van een hechte en liefdevolle dansfamilie.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-imge-box">
                                <img src="images/about-us.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default AboutUsSection;

