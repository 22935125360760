import { Outlet } from "react-router";
import { HeaderComponent as Header } from "./Header";
import { FooterComponent as Footer } from "./Footer";

function Layout({ testComponent: TestComponent }) {
    return (
        <div>
            <Header />
            {TestComponent ? <TestComponent /> : <Outlet />}
            <Footer />
            <section className="container-fluid footer_section">
                <p>
                    Copyright © 2019 All Rights Reserved By
                    <a href="https://html.design/">html.design</a>
                </p>
            </section>
        </div>
    )
}

export default Layout;