import React, { useContext } from "react";
import { Link } from "react-router";
import { AppContext } from "../context/AppContext"
const menu = require('../constants/menu.json')

function Footer() {
    const [isMobile] = useContext(AppContext);
    return (
        <section className="info_section" data-testid="footer-component">
            <div className="container">
                <div className="row custom_border-btm mb-3 pb-4">
                    <div className="col-md-4 info_logo">
                        <h2>TM Dance Company</h2>
                        <p>
                            One team, one family
                        </p>

                    </div>
                    <div className="col-md-4 info_address">
                        <h5>Adres</h5>
                        <p>Hoofdzetel: <br />Montenaken 151, 3202 Rillaar</p>
                        <p>Danszaal: <br />Diestsesteenweg 290/2, 3202 Rillaar</p>
                        <p><a href="mailto:info@tmdancecompany.be">info@tmdancecompany.be</a></p>
                        <p>Ondernemingsnummer: 0800.710.551</p>
                    </div>
                    <div className="col-md-4 info_logo">
                        <p>Aangesloten bij</p>
                        <a href="https://www.danssportvlaanderen.be/" target="_blank" rel="noreferrer">
                            <img src="images/danssportvlaanderenblack.jpeg" alt="danssport vlaanderen" width={"160px"} />
                        </a>
                        <a href="https://www.sport.vlaanderen/" target="_blank" rel="noreferrer">
                            <img src="images/sportvlaanderen.jpeg" alt="sport vlaanderen" width={"160px"} />
                        </a>
                    </div>
                </div>
                {!isMobile && <div className="info_nav">
                    <nav className="custom_border-btm">
                        <ul>
                            {menu.map((item, index) => (
                                <li key={`footer-menu-${index}`}>
                                    <Link to={item.to}>{item.menuTitle}</Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>}
                <div>
                    <div className="info_social">
                        <div>
                            <a href="https://www.facebook.com/profile.php?id=100090435891145" target="_blank" rel="noreferrer">
                                <img src="images/fb.png" alt="facebook link" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/tm.dancecompany/" target="_blank" rel="noreferrer">
                                <img src="images/insta.png" alt="instagram link" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export function FooterComponent() {
    return <Footer />;
}
export default Footer;
