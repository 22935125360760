

function APIClubSection() {

    return (
        <>
            <section className="about_section api_section">
                <div className="container">
                    <h2>Club-API</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-detail">
                                <p>Wist je dat we bij TM Dance Company een club-API hebben? Onze club-API is een laagdrempelig aanspreekpunt bij wie dansers, ouders, lesgevers, vrijwilligers en alle andere betrokkenen van onze dansschool terecht kunnen met vragen, vermoedens of klachten over grensoverschrijdend gedrag.</p>
                                <p>API staat voor Aanspreekpunt Integriteit. Binnen TMDC kan je hiervoor terecht bij Tinne.</p>
                                <p>Je kan in alle vertrouwen je verhaal kwijt en dit wordt ook in alle discretie verder behandeld. Er kan ook advies verleend worden bij mogelijke vervolgstappen of er kan worden doorverwezen naar andere ondersteunende instanties.</p>
                                <p className="contact-info">
                                    <strong>Contactgegevens:</strong><br />
                                    Tinne Maes<br />
                                    Tinne-maes@hotmail.com<br />
                                    <a href="mailto:tinne-maes@hotmail.com">Contact</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default APIClubSection;

