import React, { useContext } from "react";
import { useParams } from "react-router";
import OneArticlePage from '../components/OneArticlePage'
import {apiLessonDataContext} from '../context/AppContext'
function Les() {
    const { lessonId } = useParams();
    const [apiLessonData] = useContext(apiLessonDataContext);
    const lesson = apiLessonData.find(obj => obj.link === lessonId)
    return (
        <>
            <div className="about-page" data-testid="lesson-page">
                <OneArticlePage item={lesson} />
            </div>
        </>
    );
}
export default Les; 