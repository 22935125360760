import { Link } from "react-router";
import { useState } from "react";
const menu = require('../constants/menu.json')

function Header() {
    const [expanded,setExpanded] = useState(false)
    return (
        <div className="hero_area" data-testid="header-component">
            <header className="header_section">
                <div className="container">
                    <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
                        <Link className="navbar-brand" to="/">
                            TMDC
                        </Link>
                        <button
                            className={`navbar-toggler ${!expanded ? 'collapsed' : ''}`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => setExpanded(!expanded)}
                            expanded={expanded.toString()}
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div
                            className={`collapse navbar-collapse ${expanded ? 'show' : ''}`}
                            id="navbarSupportedContent"
                            data-testid="menu-container"
                        >
                            <ul className="navbar-nav ml-auto mr-2">
                                {menu.map((item, index) => (
                                    <li key={`header-menu-${index}`} className="nav-item">
                                        <Link className="nav-link" to={item.to} onClick={()=>setExpanded(false)}>
                                            {item.menuTitle}{" "}
                                        </Link>
                                    </li>
                                ))}
                                {/* <li className="nav-item active">
                                    <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="about.html">About Us </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="dance.html">Dance</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="lessenrooster.html">Lessenrooster</a>
                                </li> */}
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export function HeaderComponent() {
    return <Header />;
  }
export default Header;