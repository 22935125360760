import React from "react";
import LessonTable from './LessonTable'
import { Link } from "react-router";
function OneArticlePage({ item }) {

    if (!(item)) {
        return <>Niet gevonden</>
    }
    return (
        <>
            <section className="about_section" key="lesson-body">
                <div className="container">
                    <h2>
                        {item.name}
                    </h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-detail">
                                {item.body.split('\n').map((str, i) => <p key={item.name + "-" + i}>{str}</p>)}
                                {!('schedules' in item) && <><br/><Link to="https://www.ledenbeheer.be/public/tmdancecompany">
                                    Inschrijven
                                </Link></>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-imge-box">
                                <img src={item.img} alt={`${item.name}`} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {('schedules' in item) &&
                <section className="about_section" key="lesson-table">
                    <div className='container'>
                        <h2>
                            Lessenrooster voor {item.name}
                        </h2>
                        <LessonTable lessons={[item]} />
                    </div>
                </section>
            }
        </>

    )
}

export default OneArticlePage
