import AboutUsSection from "../components/AboutUsSection";
import APIClubSection from "../components/APIClubSection";


function AboutUs() {
    return (
        <>
            <div className="about-page"  data-testid="about-us-page">
                <AboutUsSection />
                <APIClubSection />
            </div>
        </>
    );
}
export default AboutUs; 