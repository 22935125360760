import React, { useContext } from "react";
import { useParams } from "react-router";
import OneArticlePage from '../components/OneArticlePage'
import {apiEventDataContext} from '../context/AppContext'
function Event() {
    const { eventId } = useParams();
    const [apiEventData] = useContext(apiEventDataContext);
    const item = apiEventData.find(obj => obj.link === eventId)
    return (
        <>
            <div className="about-page" data-testid="lesson-page">
                <OneArticlePage item={item} />
            </div>
        </>
    );
}
export default Event; 